<template>
  <b-row>
    <b-col style="zoom: 85%">
      <b-overlay :show="isLoading">
        <form-wizard
          ref="wizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :start-index.sync="tabIndex"
          next-button-text="Lanjut"
          back-button-text="Kembali"
          finish-button-text="Selesai"
        >
          <tab-content
            v-for="component in dynamicComponents"
            :key="component.id"
            lazy
            :title="component.title"
            :before-change="component.beforeChange"
          >
            <component
              :is="component.component"
              v-if="component.component === 'PreviewProductVue'"
              @productsSelected="form.products = $event"
            />
            <component
              :is="component.component"
              v-else-if="component.component === 'ChooseShopVue'"
              @selectedShops="form.shops = $event"
            />
            <component
              :is="component.component"
              v-else-if="component.component === 'ChooseMarketplaceCategoryVue'"
              :products="form.products"
              :shops="form.shops"
              :category="form.category"
            />

            <component
              :is="component.component"
              v-else-if="component.component === 'ProductInformationVue'"
              :products="form.products"
              :categories="form.category"
              :shops="form.shops"
              :informations="form.information"
              @update:informations="form.information = $event"
            />
            <component :is="component.component" v-else />
          </tab-content>
        </form-wizard>
      </b-overlay>
    </b-col>
    <!-- <b-col cols="12">
      <pre>{{ tokopediaCategory }}</pre>
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BOverlay } from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import axios from 'axios';
import PreviewProductVue from './PreviewProduct.vue';
import ChooseShopVue from './ChooseShop.vue';
import ChooseMarketplaceCategoryVue from './ChooseMarketplaceCategory.vue';
import ProductInformationVue from './ProductInformation.vue';

export default defineComponent({
  name: 'ProductCopyForm',
  components: {
    BRow,
    BCol,
    BCard,
    FormWizard,
    TabContent,
    WizardButton,
    ToastificationContent,
    PreviewProductVue,
    ChooseShopVue,
    ChooseMarketplaceCategoryVue,
    ProductInformationVue,
    BOverlay,
  },
  data() {
    return {
      form: {
        products: [],
        shops: [],
        category: [],
        information: [],
      },
      isLoading: false,
      tabIndex: 0,
      tokopediaCategory: [],
      dynamicComponents: [
        {
          id: 1,
          component: 'PreviewProductVue',
          title: 'Pratinjau Produk',
          beforeChange: () => this.checkProducts(),
        },
        {
          id: 2,
          component: 'ChooseShopVue',
          title: 'Pilih Toko',
          beforeChange: () => this.checkShops(),
        },
        {
          id: 3,
          component: 'ChooseMarketplaceCategoryVue',
          title: 'Kategori produk',
          beforeChange: () => this.checkCategories(),
        },
        {
          id: 4,
          component: 'ProductInformationVue',
          title: 'Informasi produk',
          beforeChange: () => this.finish(),
        },
        // {
        //   id: 5,
        //   component: 'BCard',
        //   title: 'Selesai',
        //   beforeChange: () => console.log('before change'),
        // },
      ],
    };
  },
  methods: {
    showAlertToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kesalahan!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: message,
        },
      });
    },

    checkShops() {
      return new Promise((resolve, reject) => {
        if (this.form.shops.length > 0) {
          const shopIcons = ['tokopedia', 'shopee', 'lazada'];

          this.form.category = this.form.products.map(product => {
            const productShops = {};

            // Initialize productShops with empty arrays for each shop icon
            shopIcons.forEach(icon => {
              productShops[icon] = [];
            });

            // Fill in productShops with shop data
            this.form.shops.forEach(shop => {
              productShops[shop.icon].push({
                shopId: shop.id,
                category: '',
                brand: '',
              });
            });

            return {
              id: product._id,
              name: product.title,
              shops: productShops,
            };
          });

          resolve(true);
        } else {
          this.showAlertToast('Pilih setidaknya satu toko untuk melanjutkan.');
          reject(new Error('No shops selected'));
        }
      });
    },

    checkProducts() {
      return new Promise((resolve, reject) => {
        if (this.$store.state.master.productSelected.length > 0) {
          this.form.products = this.$store.state.master.productSelected;
          resolve(true);
        } else {
          this.showAlertToast(
            'Pilih setidaknya satu produk untuk melanjutkan.',
          );
          reject(new Error('No products selected'));
        }
      });
    },

    checkCategories() {
      return new Promise((resolve, reject) => {
        // make sure all empty values are filled
        let emptyCategoryCount = 0;

        this.form.category.forEach(product => {
          Object.keys(product.shops).forEach(shop => {
            product.shops[shop].forEach(d => {
              if (!d.category) {
                emptyCategoryCount = +1;
              }
            });
          });
        });

        if (emptyCategoryCount > 0) {
          this.showAlertToast(
            'Isi semua kategori atau merek (jika ada) untuk melanjutkan.',
          );
          reject(new Error('Empty category'));
        }

        // Hard code the shop icons
        const shopIcons = ['tokopedia', 'shopee', 'lazada'];

        this.form.information = this.form.products.map(product => {
          const productShops = {};

          // Initialize productShops with empty arrays for each shop icon
          shopIcons.forEach(icon => {
            productShops[icon] = [];
          });

          // Fill in productShops with shop data
          this.form.shops.forEach(shop => {
            if (!productShops[shop.icon]) {
              productShops[shop.icon] = [];
            }

            const data = {
              shopId: shop.id,
              price: product.price,
              stock: product.stock,
              description: product.description,
              existingImages: product.images,
              dimension: {
                length: product.length,
                width: product.width,
                height: product.height,
                weight: product.weight,
              },
              category: this.form.category.find(p => p.id === product._id)
                .shops[shop.icon],
              variant: {},
              additional: {},
            };

            // Add additional properties based on shop icon
            if (shop.icon === 'tokopedia') {
              data.additional = {
                status: 'unlimited',
                minimalPurchase: 1,
                condition: 'new',
                shippingInsurance: 'yes',
              };
            }

            productShops[shop.icon].push(data);
          });

          return {
            id: product._id,
            name: product.title,
            shops: productShops,
          };
        });

        resolve(true);
      });
    },

    async finish() {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        if (this.form.information.length > 0) {
          // Disable all buttons with class 'wizard-btn'
          const wizardButtons = document.querySelectorAll('.wizard-btn');
          wizardButtons.forEach(button => {
            button.disabled = true;
          });

          // Show loading indicator
          this.$swal({
            title: 'Loading...',
            text: 'Mohon tunggu sebentar... Produk sedang disalin.',
            icon: 'info',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });

          axios
            .post(
              'https://kanaldagang.free.beeceptor.com/salin',
              this.form.information,
            )
            .then(response => {
              console.log('response', response.data);
              // Close loading indicator
              this.$swal.close();

              // Enable all buttons with class 'wizard-btn'
              wizardButtons.forEach(button => {
                button.disabled = false;
              });

              this.$swal({
                title: 'Berhasil!',
                text: 'Produk berhasil disalin.',
                icon: 'success',
              }).then(() => {
                this.$router.push({ name: 'productsMaster' });
              });
              resolve(true);
            })
            .catch(error => {
              console.log('error', error);
              // Close loading indicator
              this.$swal.close();

              // Enable all buttons with class 'wizard-btn'
              wizardButtons.forEach(button => {
                button.disabled = false;
              });

              // Show error alert
              this.$swal({
                title: 'Error!',
                text: 'Failed to copy product. Please try again later.',
                icon: 'error',
              });
              this.isLoading = false;
              reject(new Error('Failed to copy product'));
            });
        } else {
          this.showAlertToast('Isi semua informasi produk untuk melanjutkan.');
          this.isLoading = false;
          reject(new Error('Empty information'));
        }
      });
    },
  },
  // clear product information if shop is changed
  watch: {
    'form.shops': {
      handler() {
        if (this.form.information.length > 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Perhatian!',
              icon: 'InfoIcon',
              variant: 'info',
              text: 'Informasi produk dan kategori akan dihapus karena toko yang dipilih telah diubah.',
            },
          });

          this.form.information = [];
          this.form.category = [];
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.vue-form-wizard .wizard-navigation .wizard-nav {
  pointer-events: none;
  cursor: not-allowed;
}
</style>
