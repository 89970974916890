<template>
  <b-row>
    <b-col cols="12">
      <b-table-simple borderless small>
        <b-tbody>
          <template v-for="(product, pIndex) in products">
            <b-tr :key="product._id">
              <b-td>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-img
                      style="
                        width: 90px;
                        height: 90px;
                        object-fit: cover;
                        object-position: center;
                        background-color: transparent;
                      "
                      :alt="product.title"
                      :src="product.firstImage"
                      thumbnail
                    />
                  </template>
                  <h3 class="mt-0 mb-1 text-primary">
                    <b>{{ product.title }}</b>
                  </h3>
                  <small class="mb-0"><b>MSKU :</b> {{ product.sku }}</small>
                  <br />
                  <small class="mb-0"
                    ><b>Harga :</b> {{ product.priceRupiah }}</small
                  >
                </b-media>
              </b-td>
              <b-td class="pull-right">
                <dir class="d-flex justify-content-end">
                  <b-img
                    v-for="(img, iImg) in product.images"
                    :key="img"
                    :src="img"
                    :alt="product.title + ' image ' + (iImg + 1)"
                    :title="product.title + ' image ' + (iImg + 1)"
                    style="
                      width: 60px;
                      height: 60px;
                      object-fit: cover;
                      object-position: center;
                      background-color: transparent;
                    "
                    class="mr-1"
                    thumbnail
                  />
                </dir>
              </b-td>
            </b-tr>
            <template v-for="(shop, sIndex) in shops">
              <!-- eslint-disable-next-line vue/valid-v-for -->
              <b-tr :key="shop._id" v-bind="shop">
                <b-td colspan="2">
                  <b-table-simple class="mt-2">
                    <b-thead>
                      <b-tr>
                        <b-th class="bg-transparent" style="width: 25%"></b-th>
                        <b-th class="bg-transparent"
                          >informasi dan varian produk</b-th
                        >
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          <b-media vertical-align="center">
                            <template #aside>
                              <b-img
                                style="
                                  width: 50px;
                                  height: 50px;
                                  object-fit: cover;
                                  object-position: center;
                                  background-color: transparent;
                                "
                                :alt="shop.title"
                                :src="
                                  require('@/assets/images/marketplace/' +
                                    shop.icon +
                                    '.png')
                                "
                              />
                            </template>
                            <h5 class="mt-0 mb-1 text-primary">
                              <b>#{{ sIndex + 1 }} {{ shop.title }}</b>
                            </h5>
                            <small class="mb-0">
                              <b>Marketplace : </b> {{ shop.icon }} <br />
                              <b>ID :</b>
                              {{ shop.shopId }}
                            </small>
                          </b-media>
                          <hr />
                        </b-td>
                        <b-td>
                          <!-- basic table -->
                          <b-table-simple bordered>
                            <b-thead>
                              <b-tr>
                                <b-th class="bg-transparent">Stok</b-th>
                                <b-th class="bg-transparent">Harga</b-th>
                                <b-th class="bg-transparent"
                                  >Tinggi (H - CM)</b-th
                                >
                                <b-th class="bg-transparent"
                                  >Lebar (W - CM)</b-th
                                >
                                <b-th class="bg-transparent"
                                  >Panjang (L - CM)</b-th
                                >
                                <b-th class="bg-transparent">Berat (Kg)</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.stock"
                                    placeholder="Stok"
                                    @input="
                                      tokopediaChangeValue(
                                        'stock',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.price"
                                    placeholder="Harga"
                                    @input="
                                      tokopediaChangeValue(
                                        'price',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.height"
                                    placeholder="Tinggi"
                                    @input="
                                      tokopediaChangeValue(
                                        'height',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.width"
                                    placeholder="Lebar"
                                    @input="
                                      tokopediaChangeValue(
                                        'width',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.length"
                                    placeholder="Panjang"
                                    @input="
                                      tokopediaChangeValue(
                                        'length',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td>
                                  <cleave
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="product.weight"
                                    placeholder="Berat"
                                    @input="
                                      tokopediaChangeValue(
                                        'weight',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>

                          <!-- additional table (tokopedia) -->
                          <b-table-simple
                            v-if="
                              shop.icon === 'tokopedia' &&
                              information &&
                              information[pIndex] &&
                              information[pIndex].shops &&
                              information[pIndex].shops.tokopedia
                            "
                            bordered
                          >
                            <b-thead>
                              <b-tr>
                                <b-th class="bg-transparent"
                                  >Status Produk</b-th
                                >
                                <b-th class="bg-transparent"
                                  >Minimal Pembelian</b-th
                                >
                                <b-th class="bg-transparent">Kondisi</b-th>
                                <b-th class="bg-transparent"
                                  >Asuransi Pengiriman</b-th
                                >
                                <b-th class="bg-transparent"></b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr
                                v-for="tokped in information[pIndex].shops
                                  .tokopedia"
                                :key="tokped.shopId"
                              >
                                <b-td v-if="shop.id === tokped.shopId">
                                  <b-form-select
                                    :id="`status-tokopedia-${tokped.shopId}-${product._id}`"
                                    :value="tokped.additional.status"
                                    size="sm"
                                    placeholder="Status Produk"
                                    :options="[
                                      { value: null, text: 'Pilih Status' },
                                      { value: 'limited', text: 'Terbatas' },
                                      {
                                        value: 'unlimited',
                                        text: 'Tidak Terbatas',
                                      },
                                      { value: 'empty', text: 'Kosong' },
                                    ]"
                                    @change="
                                      tokopediaChangeValue(
                                        'status',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td v-if="shop.id === tokped.shopId">
                                  <cleave
                                    :id="`minimal-purchase-tokopedia-${tokped.shopId}-${product._id}`"
                                    :options="{
                                      numeral: true,
                                      numeralDecimalMark: ',',
                                      delimiter: '.',
                                    }"
                                    class="form-control form-control-sm"
                                    :value="tokped.additional.minimalPurchase"
                                    placeholder="Minimal Pembelian"
                                    @input="
                                      tokopediaChangeValue(
                                        'minimalPurchase',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td v-if="shop.id === tokped.shopId">
                                  <b-form-select
                                    :id="`condition-tokepedia-${tokped.shopId}-${product._id}`"
                                    size="sm"
                                    :value="tokped.additional.condition"
                                    placeholder="Kondisi"
                                    :options="[
                                      { value: null, text: 'Pilih Kondisi' },
                                      { value: 'new', text: 'Baru' },
                                      { value: 'second', text: 'Bekas' },
                                    ]"
                                    @change="
                                      tokopediaChangeValue(
                                        'condition',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td v-if="shop.id === tokped.shopId">
                                  <b-form-select
                                    :id="`shipping-insurance-tokopedia-${tokped.shopId}-${product._id}`"
                                    size="sm"
                                    :value="tokped.additional.shippingInsurance"
                                    placeholder="Asuransi Pengiriman"
                                    :options="[
                                      { value: null, text: 'Pilih Asuransi' },
                                      { value: 'yes', text: 'Ya' },
                                      { value: 'no', text: 'Tidak' },
                                    ]"
                                    @change="
                                      tokopediaChangeValue(
                                        'shippingInsurance',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                                <b-td v-if="shop.id === tokped.shopId">
                                  <b-button
                                    size="sm"
                                    variant="outline-primary"
                                    @click="
                                      tokopediaCopyAdditional(
                                        product._id,
                                        shop.id,
                                      )
                                    "
                                  >
                                    <Feather-icon icon="CopyIcon" /> Salin
                                    Informasi
                                  </b-button>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>

                          <b-table-simple bordered>
                            <!-- deskripsi -->
                            <b-thead>
                              <b-tr>
                                <b-th class="bg-transparent">Deskripsi</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td>
                                  <b-form-textarea
                                    size="sm"
                                    :value="product.description"
                                    rows="3"
                                    no-auto-shrink
                                    no-resize
                                    placeholder="Deskripsi"
                                    @input="
                                      tokopediaChangeValue(
                                        'description',
                                        $event,
                                        shop.id,
                                        product._id,
                                      )
                                    "
                                  />
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </template>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-col>
    <!-- <b-col cols="12">
      <b-card>
        <pre>{{ information }}</pre>
      </b-card>
    </b-col> -->
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BImg,
  BMedia,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BButton,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';

export default defineComponent({
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    shops: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    informations: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BImg,
    BMedia,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    ToastificationContent,
    Cleave,
  },
  data() {
    return {
      information: [],
    };
  },
  methods: {
    tokopediaChangeValue(type = 'height', value, shopId, productId) {
      // check tif tokopedia shops length > 0
      if (
        this.information.length === 0 ||
        this.information[0].shops.tokopedia.length === 0
      ) {
        return;
      }

      const getProductIndex = this.information.findIndex(
        product => product.id === productId,
      );

      const getShopIndex = this.information[
        getProductIndex
      ].shops.tokopedia.findIndex(shop => shop.shopId === shopId);

      // if this.information[getProductIndex].shops.tokopedia[getShopIndex] undefined, skip
      if (!this.information[getProductIndex].shops.tokopedia[getShopIndex]) {
        return;
      }

      // check if dimension is exist
      if (
        !this.information[getProductIndex].shops.tokopedia[getShopIndex]
          .dimension
      ) {
        this.$set(
          this.information[getProductIndex].shops.tokopedia[getShopIndex],
          'dimension',
          {},
        );
      }

      const dimensions = ['length', 'width', 'height', 'weight'];

      if (dimensions.includes(type)) {
        this.information[getProductIndex].shops.tokopedia[
          getShopIndex
        ].dimension[type] = value;
      }

      const basics = ['price', 'stock', 'description'];

      if (basics.includes(type)) {
        this.information[getProductIndex].shops.tokopedia[getShopIndex][type] =
          value;
      }

      const additionals = [
        'status',
        'minimalPurchase',
        'condition',
        'shippingInsurance',
      ];

      if (additionals.includes(type)) {
        // create type key if not exist
        if (
          !this.information[getProductIndex].shops.tokopedia[getShopIndex]
            .additional[type]
        ) {
          this.$set(
            this.information[getProductIndex].shops.tokopedia[getShopIndex]
              .additional,
            type,
            value,
          );
        } else {
          this.information[getProductIndex].shops.tokopedia[
            getShopIndex
          ].additional[type] = value;
        }
      }
    },

    tokopediaCopyAdditional(productId, shopId) {
      // make sure master shop is additonal key is and its value is not empty
      const product = this.information.find(p => productId === p.id);

      const master = product.shops.tokopedia.find(s => shopId === s.shopId);

      this.information.forEach(info => {
        if (info.shops && info.shops.tokopedia) {
          info.shops.tokopedia.forEach(shop => {
            this.$set(shop.additional, 'status', master.additional.status);
            this.$set(
              shop.additional,
              'minimalPurchase',
              master.additional.minimalPurchase,
            );
            this.$set(
              shop.additional,
              'condition',
              master.additional.condition,
            );
            this.$set(
              shop.additional,
              'shippingInsurance',
              master.additional.shippingInsurance,
            );
          });
        }
      });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Salin Informasi',
          icon: 'CheckIcon',
          variant: 'success',
          text: 'Informasi tambahan untuk tokopedia telah disalin ke seluruh toko dan produk.',
        },
      });
    },
  },
  // computed tokopedia additional
  watch: {
    informations: {
      handler() {
        this.information = this.informations;
      },
      deep: true,
    },
    information: {
      handler() {
        this.$emit('update:informations', this.information);
      },
      deep: true,
    },
  },
});
</script>
