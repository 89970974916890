var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-table-simple',[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"bg-transparent",staticStyle:{"width":"30%"}},[_vm._v("Produk")]),_c('b-th',{staticClass:"bg-transparent text-center"},[_vm._v("Toko")])],1)],1),_c('b-tbody',_vm._l((_vm.products),function(product){return _c('b-tr',{key:product.id},[_c('b-td',[_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{staticStyle:{"width":"100px","height":"100px","object-fit":"cover","object-position":"center","background-color":"transparent"},attrs:{"alt":product.title,"src":product.firstImage,"thumbnail":""}})]},proxy:true}],null,true)},[_c('h5',{staticClass:"mt-0 mb-1 text-primary"},[_c('b',[_vm._v(_vm._s(product.title))])]),_c('small',{staticClass:"mb-0"},[_c('b',[_vm._v("SKU :")]),_vm._v(" "+_vm._s(product.sku))])])],1),_c('b-td',[_c('b-table-simple',[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"bg-transparent",staticStyle:{"width":"30%"}},[_vm._v("Nama Toko")]),_c('b-th',{staticClass:"bg-transparent",staticStyle:{"width":"65%"}},[_vm._v(" Kategori ")])],1)],1),_c('b-tbody',_vm._l((_vm.shops),function(shop,sIndex){return _c('b-tr',{key:shop.id},[_c('b-td',[(shop.title)?_c('b-img',{staticClass:"mr-1",staticStyle:{"width":"40px","height":"40px","object-fit":"cover","object-position":"center","background-color":"transparent"},attrs:{"src":require('@/assets/images/marketplace/' +
                        shop.icon +
                        '.png')}}):_vm._e(),_c('b',[_vm._v(_vm._s(shop.title))])],1),(shop.icon === 'tokopedia')?_c('b-td',[_c('div',{staticClass:"d-flex flex-column bd-highlight mb-1"},[_c('b-input-group',[_c('b-form-input',{staticClass:"category-search",attrs:{"id":("tokopedia-search-" + (shop.id) + "-" + (product._id) + "-" + (sIndex + 1)),"size":"sm","placeholder":"ketik sesuatu untuk mencari kategori","debounce":"500"},on:{"input":function($event){return _vm.debouncedFetchTokopedia(
                            $event,
                            shop.id,
                            product._id,
                            true
                          )}}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.copySearch(
                              ("tokopedia-search-" + (shop.id) + "-" + (product._id) + "-" + (sIndex + 1))
                            )}}},[_c('Feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" Salin Pencarian ")],1)],1)],1),(
                        _vm.tokopedia[product._id] &&
                        _vm.tokopedia[product._id][shop.id]
                      )?_c('b-form-radio-group',{ref:("tokopedia-category-" + (shop.id) + "-" + (product._id)),refInFor:true,staticClass:"mt-1 ml-1",attrs:{"options":_vm.tokopedia[product._id][shop.id],"size":"sm","stacked":""},on:{"change":function($event){return _vm.selectTokopediaCategory($event, product._id, shop.id)}}}):_vm._e()],1)]):_vm._e(),(shop.icon === 'lazada')?_c('b-td',[_c('div',{staticClass:"d-flex flex-column bd-highlight mb-1"},[_c('b-input-group',[_c('b-form-input',{staticClass:"category-search",attrs:{"id":("lazada-search-" + (shop.id) + "-" + (product._id) + "-" + (sIndex + 1)),"size":"sm","placeholder":"ketik sesuatu untuk mencari kategori","debounce":"500"},on:{"input":function($event){return _vm.debouncedFetchLazada(
                            $event,
                            shop.id,
                            product._id,
                            true
                          )}}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.copySearch(
                              ("lazada-search-" + (shop.id) + "-" + (product._id) + "-" + (sIndex + 1))
                            )}}},[_c('Feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" Salin Pencarian ")],1)],1)],1),(
                        _vm.lazada[product._id] && _vm.lazada[product._id][shop.id]
                      )?_c('b-form-radio-group',{ref:("lazada-category-" + (shop.id) + "-" + (product._id)),refInFor:true,staticClass:"mt-1 ml-1",attrs:{"options":_vm.lazada[product._id][shop.id],"size":"sm","stacked":""},on:{"change":function($event){return _vm.selectLazadaCategory($event, product._id, shop.id)}}}):_c('span',[_vm._v("Hasil tidak ditemukan")])],1)]):_vm._e(),(shop.icon === 'shopee')?_c('b-td',[_c('div',{staticClass:"d-flex flex-column bd-highlight mb-1"},[_c('b-form-input',{attrs:{"size":"sm","placeholder":"cari kategori","disabled":""}}),_c('b-form-select',{staticClass:"mt-1",attrs:{"size":"sm","options":[1, 2, 3],"disabled":""}})],1)]):_vm._e()],1)}),1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }