<template>
  <b-row class="mb-2">
    <b-col md="6">
      <h6 class="text-primary font-weight-bold mb-2">Toko Tersedia</h6>

      <!-- draggable -->
      <draggable
        :list="shops"
        tag="ul"
        group="tags"
        class="list-group list-group-flush cursor-move"
        style="min-height: 200px"
      >
        <b-list-group-item v-for="(m, index) in shops" :key="index" tag="li">
          <div class="d-flex align-items-center">
            <b-avatar
              v-if="m.title"
              :src="require('@/assets/images/marketplace/' + m.icon + '.png')"
              variant="light"
              square
            />
            <div class="ml-25 flex-grow-1">
              <b-card-text class="mb-0 font-weight-bold">
                {{ m.title }}
              </b-card-text>
              <small class="d-flex align-items-center">
                {{ marketplaceName(m.icon) }}
              </small>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
    </b-col>

    <!-- tags group 2 -->
    <b-col md="6" class="mt-sm-2 mt-md-0">
      <h6 class="text-primary font-weight-bold mb-2">Toko Terpilih</h6>

      <!-- draggable -->
      <draggable
        :list="selectedShops"
        tag="ul"
        group="tags"
        class="list-group list-group-flush cursor-move"
        style="min-height: 200px"
      >
        <b-list-group-item
          v-for="(m, index) in selectedShops"
          :key="index"
          tag="li"
        >
          <div class="d-flex align-items-center">
            <b-avatar
              v-if="m.title"
              :src="require('@/assets/images/marketplace/' + m.icon + '.png')"
              variant="light"
              square
            />
            <div class="ml-25 flex-grow-1">
              <b-card-text class="mb-0 font-weight-bold">
                {{ m.title }}
              </b-card-text>
              <small class="d-flex align-items-center">
                {{ marketplaceName(m.icon) }}
              </small>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BListGroupItem,
  BImg,
  BCardText,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import axios from '@axios';

export default defineComponent({
  name: 'ChooseShop',
  components: {
    BRow,
    BCol,
    BCard,
    draggable,
    Prism,
    BAvatar,
    BListGroupItem,
    BImg,
    BCardText,
  },
  data() {
    return {
      shops: [],
      selectedShops: [],
    };
  },
  async mounted() {
    await this.fetchShops();
  },
  methods: {
    async fetchShops() {
      // load shop options
      const url = 'https://api.kanaldagang.com/marketplace?page=1&per_page=10';
      const response = await axios.get(url);

      if (response && response.data && response.data.data.length > 0) {
        this.shops = response.data.data.map(shop => ({
          title: shop.shopName,
          icon: shop.marketplaceName,
          id: shop.id,
          shopId: shop.shopId,
        }));
      }
    },
  },
  // computed marketplace name to capitalize first letter
  computed: {
    marketplaceName() {
      return icon => {
        const name = icon.replace(/-/g, ' ');
        return name.charAt(0).toUpperCase() + name.slice(1);
      };
    },
  },

  // emit event to parent
  watch: {
    selectedShops: {
      handler(val) {
        this.$emit('selectedShops', val);
      },
      deep: true,
    },
  },
});
</script>
