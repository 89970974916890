<template>
  <b-overlay :show="isLoading">
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 30%">Produk</b-th>
          <b-th class="bg-transparent text-center">Toko</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="product in products" :key="product.id">
          <b-td>
            <b-media vertical-align="center">
              <template #aside>
                <b-img
                  style="
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    object-position: center;
                    background-color: transparent;
                  "
                  :alt="product.title"
                  :src="product.firstImage"
                  thumbnail
                ></b-img>
              </template>
              <h5 class="mt-0 mb-1 text-primary">
                <b>{{ product.title }}</b>
              </h5>
              <small class="mb-0"><b>SKU :</b> {{ product.sku }}</small>
            </b-media>
          </b-td>
          <b-td>
            <b-table-simple>
              <b-thead>
                <b-tr>
                  <b-th class="bg-transparent" style="width: 30%"
                    >Nama Toko</b-th
                  >
                  <b-th class="bg-transparent" style="width: 65%">
                    Kategori
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(shop, sIndex) in shops" :key="shop.id">
                  <b-td>
                    <b-img
                      v-if="shop.title"
                      :src="
                        require('@/assets/images/marketplace/' +
                          shop.icon +
                          '.png')
                      "
                      style="
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        object-position: center;
                        background-color: transparent;
                      "
                      class="mr-1"
                    />
                    <b>{{ shop.title }}</b>
                  </b-td>
                  <b-td v-if="shop.icon === 'tokopedia'">
                    <div class="d-flex flex-column bd-highlight mb-1">
                      <!-- <b-form-input
                        size="sm"
                        placeholder="ketik sesuatu untuk mencari kategori"
                        debounce="500"
                        @input="
                          debouncedFetchTokopedia($event, shop.id, product._id)
                        "
                      /> -->
                      <b-input-group>
                        <b-form-input
                          :id="`tokopedia-search-${shop.id}-${product._id}-${
                            sIndex + 1
                          }`"
                          size="sm"
                          placeholder="ketik sesuatu untuk mencari kategori"
                          debounce="500"
                          class="category-search"
                          @input="
                            debouncedFetchTokopedia(
                              $event,
                              shop.id,
                              product._id,
                              true,
                            )
                          "
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            @click="
                              copySearch(
                                `tokopedia-search-${shop.id}-${product._id}-${
                                  sIndex + 1
                                }`,
                              )
                            "
                          >
                            <Feather-icon icon="CopyIcon" />
                            Salin Pencarian
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                      <b-form-radio-group
                        v-if="
                          tokopedia[product._id] &&
                          tokopedia[product._id][shop.id]
                        "
                        :ref="`tokopedia-category-${shop.id}-${product._id}`"
                        :options="tokopedia[product._id][shop.id]"
                        size="sm"
                        class="mt-1 ml-1"
                        stacked
                        @change="
                          selectTokopediaCategory($event, product._id, shop.id)
                        "
                      />
                    </div>
                  </b-td>
                  <b-td v-if="shop.icon === 'lazada'">
                    <div class="d-flex flex-column bd-highlight mb-1">
                      <!-- <b-form-input
                        size="sm"
                        placeholder="ketik sesuatu untuk mencari kategori"
                        debounce="500"
                        class="category-search"
                        @input="
                          debouncedFetchLazada($event, shop.id, product._id)
                        "
                      /> -->

                      <b-input-group>
                        <b-form-input
                          :id="`lazada-search-${shop.id}-${product._id}-${
                            sIndex + 1
                          }`"
                          size="sm"
                          placeholder="ketik sesuatu untuk mencari kategori"
                          debounce="500"
                          class="category-search"
                          @input="
                            debouncedFetchLazada(
                              $event,
                              shop.id,
                              product._id,
                              true,
                            )
                          "
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            @click="
                              copySearch(
                                `lazada-search-${shop.id}-${product._id}-${
                                  sIndex + 1
                                }`,
                              )
                            "
                          >
                            <Feather-icon icon="CopyIcon" />
                            Salin Pencarian
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                      <b-form-radio-group
                        v-if="
                          lazada[product._id] && lazada[product._id][shop.id]
                        "
                        :ref="`lazada-category-${shop.id}-${product._id}`"
                        :options="lazada[product._id][shop.id]"
                        size="sm"
                        class="mt-1 ml-1"
                        stacked
                        @change="
                          selectLazadaCategory($event, product._id, shop.id)
                        "
                      />
                      <span v-else>Hasil tidak ditemukan</span>
                    </div>
                  </b-td>
                  <b-td v-if="shop.icon === 'shopee'">
                    <div class="d-flex flex-column bd-highlight mb-1">
                      <b-form-input
                        size="sm"
                        placeholder="cari kategori"
                        disabled
                      />
                      <b-form-select
                        size="sm"
                        class="mt-1"
                        :options="[1, 2, 3]"
                        disabled
                      />
                    </div>
                  </b-td>
                  <!-- <b-td>
                  <b-form-select :id="`categorys-${shop.id}`" size="sm" />
                </b-td> -->
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <!-- <pre>
    {{ category }}
  </pre
    > -->
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTd,
  BTh,
  BTr,
  BFormSelect,
  BFormCheckbox,
  BMedia,
  BImg,
  BAvatar,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BInputGroupAppend,
  BInputGroup,
  BOverlay,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axios from '@axios';
import _ from 'lodash';

export default defineComponent({
  name: 'ChooseMarketplaceCategory',
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTd,
    BTh,
    BTr,
    BFormSelect,
    BFormCheckbox,
    BMedia,
    BImg,
    BAvatar,
    BButton,
    BFormInput,
    ToastificationContent,
    BFormRadioGroup,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    shops: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categories: [],
      tokopedia: [],
      lazada: [],
      isLoading: false,
    };
  },
  methods: {
    debouncedFetchTokopedia: _.debounce(async function fetchTokopedia(
      search,
      shopId,
      productId,
      showLoading = false,
    ) {
      if (!search) {
        return;
      }

      this.tokopedia[productId][shopId] = [];

      // pastikan search minimal 2 kata
      if (search) {
        const words = search.split(' ').length;

        if (words < 2) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Kesalahan!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Minimal harus mempunyai 2 kata (cth :sepeda lipat)',
            },
          });

          return;
        }
      }

      // clear previous category
      const productIndex = this.category.findIndex(
        product => product.id === productId,
      );

      const shopIndex = this.category[productIndex].shops.tokopedia.findIndex(
        shop => shop.shopId === shopId,
      );

      if (productIndex > -1) {
        if (
          !this.categories[productIndex].shops.tokopedia[shopIndex].category
        ) {
          this.categories[productIndex].shops.tokopedia[shopIndex].category =
            '';
        }
      }

      const params = {
        search,
        shopId,
        showloading: showLoading ? 1 : 0,
      };

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`tokopedia/product/categories?${url}`);

      if (
        response &&
        response.data &&
        response.data.data.categories &&
        response.data.data.categories.length > 0
      ) {
        this.tokopedia[productId][shopId] = response.data.data.categories.map(
          category => ({
            value: category.id,
            text: category.name,
          }),
        );
      }
    },
    800),

    debouncedFetchLazada: _.debounce(async function fetchLazada(
      search,
      shopId,
      productId,
      showLoading = false,
    ) {
      if (!search) {
        return;
      }

      // pastikan search minimal 2 kata
      if (search) {
        const words = search.split(' ').length;

        if (words < 2) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Kesalahan!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Minimal harus mempunyai 2 kata (cth :sepeda lipat)',
            },
          });

          return;
        }
      }

      // clear previous category
      const productIndex = this.category.findIndex(
        product => product.id === productId,
      );

      const shopIndex = this.category[productIndex].shops.lazada.findIndex(
        shop => shop.shopId === shopId,
      );

      if (productIndex > -1) {
        if (!this.categories[productIndex].shops.lazada[shopIndex].category) {
          this.categories[productIndex].shops.lazada[shopIndex].category = '';
        }
      }

      const params = {
        search,
        shopId,
        showloading: showLoading ? 1 : 0,
      };

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(
        `lazada/product/category/suggest?${url}`,
      );

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        this.lazada[productId][shopId] = response.data.data.map(category => ({
          value: category.categoryId,
          text: category.categoryPath,
        }));
      }
    },
    800),

    selectTokopediaCategory(category, productId, shopId) {
      // assign category to product
      const productIndex = this.category.findIndex(
        product => product.id === productId,
      );

      const shopIndex = this.category[productIndex].shops.tokopedia.findIndex(
        shop => shop.shopId === shopId,
      );

      if (productIndex > -1) {
        this.categories[productIndex].shops.tokopedia[shopIndex].category =
          category;
      }
    },

    selectLazadaCategory(category, productId, shopId) {
      // assign category to product
      const productIndex = this.category.findIndex(
        product => product.id === productId,
      );

      const shopIndex = this.category[productIndex].shops.lazada.findIndex(
        shop => shop.shopId === shopId,
      );

      if (productIndex > -1) {
        this.categories[productIndex].shops.lazada[shopIndex].category =
          category;
      }
    },

    async copySearch(id) {
      this.$swal
        .fire({
          title: 'Peringatan!',
          text: 'Menyalin kategori akan memakan waktu jika jumlah produk dan toko banyak, apakah anda yakin ingin melanjutkan?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya! Lanjutkan',
          cancelButtonText: 'Tidak! Batalkan',
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            const inputs = document.querySelectorAll('.category-search');
            const totalInputs = inputs.length;

            this.$swal.fire({
              title: `Menyalin Kategori... 1/${totalInputs}`,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didRender: () => {
                this.$swal.showLoading();
              },
            });

            const input = document.getElementById(id);

            if (!input.value) {
              this.$swal.close();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Kesalahan!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Tidak ada kata kunci pencarian',
                },
              });

              return;
            }

            for (let index = 0; index < totalInputs; index += 1) {
              const x = inputs[index];
              x.value = input.value;

              // Update SweetAlert text
              this.$swal.update({
                title: `Menyalin Kategori... ${index + 1}/${totalInputs}`,
                didRender: () => {
                  this.$swal.showLoading();
                },
              });

              // Delay for each input to prevent rate limit
              // eslint-disable-next-line no-await-in-loop
              await new Promise(resolve => setTimeout(resolve, 1500));

              const event = new Event('input', {
                bubbles: true,
                cancelable: true,
              });
              x.dispatchEvent(event);
            }

            this.$swal.update({
              title: 'Menyelesaikan...',
              didRender: () => {
                this.$swal.showLoading();
              },
            });
            await new Promise(resolve => setTimeout(resolve, 900));

            this.$swal.close();
          }
        });
    },
  },
  watch: {
    category: {
      handler(newVal) {
        this.categories = newVal;

        // clear all text input
        const inputs = document.querySelectorAll('input[type="text"]');
        inputs.forEach(input => {
          input.value = '';
        });

        this.tokopedia = newVal.reduce((acc, cur) => {
          if (cur.shops.tokopedia) {
            acc[cur.id] = cur.shops.tokopedia.reduce((accShop, curShop) => {
              accShop[curShop.shopId] = [];
              return accShop;
            }, {});
          }
          return acc;
        }, {});

        this.lazada = newVal.reduce((acc, cur) => {
          if (cur.shops.lazada) {
            acc[cur.id] = cur.shops.lazada.reduce((accShop, curShop) => {
              accShop[curShop.shopId] = [];
              return accShop;
            }, {});
          }
          return acc;
        }, {});
      },
      immediate: true,
    },
  },
});
</script>
