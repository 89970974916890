<template>
  <b-table-simple striped responsive>
    <b-thead sticky-column>
      <b-tr>
        <b-th class="bg-transparent" style="width: 40%">Nama produk</b-th>
        <b-th class="bg-transparent">Harga</b-th>
        <b-th class="bg-transparent">Stok</b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-if="tableData.length > 0">
      <b-tr v-for="(item, index) in tableData" :key="index" :item="item">
        <b-td>
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                width="60"
                :alt="item.title"
                :src="item.firstImage"
              ></b-img>
            </template>
            <h5 class="mt-0 mb-1 text-primary">
              <b>{{ item.title }}</b>
            </h5>
            <small class="mb-0"><b>SKU :</b> {{ item.sku }}</small>
          </b-media>
        </b-td>
        <b-td>
          <div class="d-flex align-items-center justify-content-between">
            <span>{{ item.priceRupiah }}</span>
          </div>
        </b-td>
        <b-td>
          <div class="d-flex align-items-center justify-content-between">
            <span>{{ item.stock }}</span>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tbody v-else>
      <b-tr>
        <b-td colspan="3" class="text-center">
          <!-- Content -->
          <div
            class="d-flex flex-column justify-content-center align-items-center h-100"
          >
            <!-- Text -->
            <span class="d-block mt-1">Tidak ada data</span>

            <!-- Button -->
            <div class="d-flex justify-content-center mt-3">
              <b-button variant="primary" :to="{ name: 'productsMaster' }">
                Kembali ke daftar produk
              </b-button>
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BFormCheckbox,
  BMedia,
  BImg,
  BButton,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProductCopyForm',
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormCheckbox,
    BMedia,
    BImg,
    BButton,
  },
  data() {
    return {
      tableData: [],
      selectAll: false,
      selectedDatas: [],
    };
  },
  async mounted() {
    // save to local storage
    if (this.$store.state.master.productSelected.length > 0) {
      localStorage.setItem(
        'copy-product-master',
        JSON.stringify(this.$store.state.master.productSelected),
      );
    }

    if (localStorage.getItem('copy-product-master')) {
      this.tableData = JSON.parse(localStorage.getItem('copy-product-master'));
      await this.$store.dispatch('master/setProductIds', this.tableData);
    }
  },
  methods: {
    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },
  },
});
</script>
